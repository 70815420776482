text {
    font-weight: bold !important;
  }
.report div p{
  font-size: 19px;
  color: black;
  font-weight: 400;
}
.chart h1{
  text-align: center;
}
.report div p span{
  font-weight: bold;
}
.report li{
  font-size: 19px;
}
.chart{
  margin: 0 auto;
}