:root{
    --ErrorMessageColor: red;
    --SuccessMessageColor: #3acc3a;
}

table {
    margin: 1rem 0 1rem 0;
    width: 80%;
    border-collapse: collapse;
}

th, td {
    border: 1px solid #ddd;
    padding: 8px;
}

th {
    background-color: #2557ae;
}
tr th{
    color: white;
}

tr:nth-child(odd){
    background-color: #86b5fd;
}

tr:nth-child(even) {
    background-color: #95befb;
}

tr:hover {
    background-color: #a6cefd;
}

button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.feedback-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Màu đen mờ */
    display: flex;
    justify-content: center;
    align-items: center;
}

.feedback-box {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.feedback-box p {
    margin-bottom: 10px;
}

.error-message{
    color: var(--ErrorMessageColor);
}
.success-message{
    color: var(--SuccessMessageColor)
}
body{
    /*Note*/
    background: radial-gradient(circle at center, #c3d6f5, #a3c7f2);  
}
.table-container{
    display:flex;
    justify-content: center;
}