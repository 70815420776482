#h1-job-description{
    color: black;
    font-size: 2rem;
    padding: 0;
    margin: 0;
}
#h2{
    color: black;
    font-size: 2rem;
    padding: 0;
    margin: 0;
    text-align: center;
    font-family: "Josefin Sans";
    font-style: normal;
	font-weight: 700;
    margin-top: 30px;
}
#h1-apply-now{
    color: black;
    font-size: 2.8rem;
    padding: 0;
    margin: 0;
    text-align: center;
}

.job-description{
    padding: 2%;
}
#job-description-block {
    border-style: solid;
    border-width: 1px;
    border-color: #86868652;
    padding: 2%;
}

input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
    font-size: 0.75em;
    color: #999;
    top: -5px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
}

.styled-input {
    float: left;
    width: 293px;
    margin: 1rem 0;
    position: relative;
    border-radius: 4px;
}

@media only screen and (max-width: 768px){
    .styled-input {
        width:100%;
    }
}

.styled-input label {
    color: #656565;
    padding: 1.3rem 30px 1rem 30px;
    position: absolute;
    top: 10px;
    left: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none;
}

.styled-input.wide { 
    width: 650px;
    max-width: 100%;
}

#input,
textarea {
    padding: 30px;
    border: 0;
    width: 100%;
    font-size: 1rem;
    border-style: solid;
    border-width: 1px;
    color: black;
    border-color: #86868652;
    border-radius: 4px;
}

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
    width: 100%;
    -webkit-transition: all 0.075s ease;
    transition: all 0.075s ease;
}

textarea {
    width: 100%;
    min-height: 15em;
}

.input-container {
    width: 650px;
    max-width: 100%;
    margin: 20px auto 25px auto;
}

.submit-btn {
    float: right;
    padding: 7px 35px;
    border-radius: 60px;
    display: inline-block;
    background-color: #4b8cfb;
    color: white;
    font-size: 18px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.06),
              0 2px 10px 0 rgba(0,0,0,0.07);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}

.submit-btn:hover {
    transform: translateY(1px);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10),
              0 1px 1px 0 rgba(0,0,0,0.09);
}

@media (max-width: 768px) {
    .submit-btn {
        width:100%;
        float: none;
        text-align:center;
    }
}

input[type=checkbox] + label {
  color: #ccc;
  font-style: italic;
} 

input[type=checkbox]:checked + label {
  color: #f00;
  font-style: normal;
}
.job-application{
    margin: 0 40% !important;
    padding: 5px 5% !important;
}
.job-detail{
    box-shadow: 0 2px 4px rgb(0, 0, 0, 0.1);
    margin-bottom: 0;
}
#job-block{
    display:flex;
    justify-content: center;
    border:none;
    margin-bottom: 0 !important;
}
.create-activity-container{
    display:flex;
    justify-content: center;
}
.create-activity-label{
}