body {
	font-family: font-family-sans-serif;
	background-color: #fff ;

	height: 200vh;
	position: relative;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: rgba(black, 0.5);
		transition: 0.3s all ease;
		opacity: 0;
		visibility: hidden;
		z-index: 1;
	}
	&.offcanvas-menu {
		&:before {
			opacity: 1;
			z-index: 1002;
			visibility: visible;
		}
	}
}
p {
	color: darken(#ccc, 10%);
	font-weight: 300;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	/* Reach Your Dreams, Apply Today! */

	font-family: "Josefin Sans" !important;
}
h1 {
	font-style: normal;
	font-weight: 700 !important;
	/* or 246px */
	letter-spacing: -0.08em !important;
	font-size: 6rem;
	color: #ffffff;
	padding: 25px !important;
}
a {
	transition: 0.3s all ease;
	&,
	&:hover {
		text-decoration: none !important;
	}
}

.hero {
	background-image: url("../images/header.png");
	height: 100vh;
	width: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: absolute;
	z-index: 1;
}
.color-header {
	background-color: red;
	height: 100vh;
	width: 100%;
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	position: absolute;
	background: rgba(19, 24, 65, 0.7);
	opacity: 0.74;
	z-index: 2;
}
.btn-mod.btn-large {
	height: auto;
	padding: 13px 52px;
	font-size: 15px;
}

.btn-mod.btn-medium {
	height: auto;
	padding: 10px 35px;
	font-size: 13px;
}

.btn-mod.btn-small {
	height: auto;
	padding: 6px 16px;
	font-size: 12px;
}

/* Button Mod to the original Bootstrap Buttons */

.btn-mod.btn-circle {
	-webkit-border-radius: 30px;
	-moz-border-radius: 30px;
	border-radius: 30px;
}

.btn-mod.btn-border {
	color: white;
	border: 2px solid white;
	background: transparent;
}

.btn-mod,
a.btn-mod {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 4px 13px;
	color: #fff;
	background: rgba(34, 34, 34, 0.9);
	border: 2px solid transparent;
	font-size: 11px;
	font-weight: 400;
	text-transform: uppercase;
	text-decoration: none;
	letter-spacing: 2px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	-webkit-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
	-moz-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
	-o-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
	-ms-transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
	transition: all 0.2s cubic-bezier(0, 0, 0.58, 1);
}

.btn-mod.btn-border:hover,
.btn-mod.btn-border:focus {
	color: #fff;
	border-color: transparent;
	background: #4182e4fe;
}

.btn-mod:hover,
.btn-mod:focus,
a.btn-mod:hover,
a.btn-mod:focus {
	font-weight: 400;
	color: rgba(255, 255, 255, 0.85);
	background: rgba(0, 0, 0, 0.7);
	text-decoration: none;
	outline: none;
	border-color: transparent;
	-webkit-box-shadow: none;
	box-shadow: none;
}

/* Reveal icon on hover */

a.btn.btn-mod.btn-border.animation-one span {
	cursor: pointer;
	display: inline-block;
	position: relative;
	transition: 0.3s;
}

a.btn.btn-mod.btn-border.animation-one span:after {
	font-family: FontAwesome;
	content: "\f105";
	position: absolute;
	opacity: 0;
	top: 0;
	right: -20px;
	transition: 0.3s;
}

a.btn.btn-mod.btn-border.animation-one:hover span {
	padding-right: 25px;
}

a.btn.btn-mod.btn-border.animation-one:hover span:after {
	opacity: 1;
	right: 0;
}
#button-sign-in {
	position: absolute;
	top: 25%;
	left: 80%;
}

/* Header */
header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 5;

	padding: 0px 0;
	@include media-breakpoint-up(md) {
		padding: 30px 0;
	}
	.navbar-brand {
		font-family: "Poppins", sans-serif;
		letter-spacing: 0.05em;
		font-weight: 700;
		font-size: 2rem;
		background-color: none;
		&.absolute {
			position: absolute;
			@include media-breakpoint-down(md) {
				position: relative;
			}
		}
		span {
			color: white;
			@include media-breakpoint-up(md) {
				color: gray("400");
			}
		}
	}

	.navbar {
		background: rgba(0, 0, 0, 1) !important;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		@include media-breakpoint-up(md) {
			padding-top: 0px;
			padding-bottom: 0;
			background: none !important;
			position: relative;
		}
		.nav-link {
			padding: 1.9rem 1rem;
			outline: none !important;
			font-size: 1.3rem;
			color: rgba(255, 255, 255, 0.8) !important;
			
			@include media-breakpoint-down(lg) {
				padding: 0.5rem 0rem;
				
			}
			&.active {
				color: white !important;
			}
		}

		.dropdown-menu {
			font-size: 14px;
			border-radius: 4px;
			border: none;
			box-shadow: 0 2px 30px 0px rgba(0, 0, 0, 0.2);
			min-width: 13em;

			margin-top: -10px;

			&:before {
				bottom: 100%;
				left: 10%;
				border: solid transparent;
				content: " ";
				height: 0;
				width: 0;
				position: absolute;
				pointer-events: none;
				border-bottom-color: #fff;
				border-width: 7px;
				@include media-breakpoint-down(md) {
					display: none;
				}
			}

			.dropdown-item {
				&:hover {
					color: white;
				}
				&.active {
					background: theme-color("primary");
					/* background: gray('100'); */
					color: white;
				}
			}
			a {
				padding-top: 7px;
				padding-bottom: 7px;
			}
		}
		.cta-btn {
			a {
				background: primary;
				color: white !important;

				text-transform: uppercase;
				font-size: 0.8rem;
				padding: 15px 20px !important;
				line-height: 1;
				font-weight: bold;
				transition: 0.3s all ease;
				&:hover {
					color: primary !important;
					box-shadow: 2px 0 30px -5px rgba(0, 0, 0, 0.2);
				}
			}
		}
	}
}

 @media (max-width: 768px) {
	h1{
		font-size: 2rem;
	}
	#button-sign-in {
		position: relative;
		top: 0;
		left: 0;
	}
 }
 @media (max-width: 1200px) {
	#button-sign-in {
		position: relative;
		top: 0;
		left: 10%;
	}
  }
 @media (min-width: 2500px) {
	h1{
		font-size: 13rem;
	}
 }
 #dropdown{
	background-color: black;
	padding: 20px;
 }
.Tilte-h1{
	font-size: 9vw;
	color: white;
}