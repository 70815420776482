#logo {
    width: 100%;
}

#btn-show-more {
    padding: 15px 30px !important;
    font-size: 14px !important;
    border: none !important;
    cursor: pointer !important;
    transition: all 0.3s ease-in-out !important;
    color: #fff !important;
    border-radius: 5px !important;
    margin: 2% 47% !important;
    outline: none !important;
    background-color: #e74c3c !important; /* Initial background color */
}

#btn-show-more:focus {
    box-shadow: none !important; /* Remove the blue border on focus */
    outline: none !important; /* Ensure the outline is removed */
}

#btn-show-more:active {
    transform: scale(0.95) !important; 
    background-color: #00ff26 !important; /* Change background color on click */
}

#btn-show-more:hover {
    background-color: #c0392b !important; /* Change background color on hover */
    transform: scale(1.1) !important;
}

#log{
  cursor: pointer;
}
#log:hover{
  box-shadow: #3d3d3d;
  cursor: pointer;
}
#job-block {
    border-style: solid;
    border-width: 1px;
    border-color: #86868652;
    padding: 3%;
}

#p-job, #p-location {
    font-size: 1.5rem;
}



#btn-apply {
    margin: 15px 0 0 0;
    border: none;
    padding: 0px 25px;
    font-size: 1.5rem;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    position: relative;
    background: transparent;
    color:  #0099ff;
    border: 3px solid #0099ff;
    cursor: pointer;
    transition: all 0.7s;
    overflow: hidden;
    border-radius: 100px;
  }

  #btn-apply:hover {
    color: #3d3d3d;
  }
  span {
    transition: all 0.7s;
    z-index: -1;
  }

  #btn-apply .first {
    content: "";
    position: absolute;
    right: 100%;
    top: 0;
    width: 25%;
    height: 100%;
    background:  #0099ff;
  }
#btn-apply:hover{
    background:  #0091f2;
}
  #btn-apply:hover .first {
    top: 0;
    right: 0;
  }
  #btn-apply .second {
    content: "";
    position: absolute;
    left: 25%;
    top: -100%;
    height: 100%;
    width: 30%;
    background:  #0099ff;
  }

  #btn-apply:hover .second {
    top: 0;
    left: 50%;
  }

  #btn-apply .third {
    content: "";
    position: absolute;
    left: 50%;
    height: 100%;
    top: 100%;
    width: 25%;
    background:  #0099ff;
  }

  #btn-apply:hover .third {
    top: 0;
    left: 25%;
  }

  #btn-apply .fourth {
    content: "";
    position: absolute;
    left: 100%;
    top: 0;
    height: 100%;
    width: 25%;
    background:  #0099ff;
  }

  #btn-apply:hover .fourth {
    top: 0;
    left: 0;
  }
  
  #job {
    cursor: pointer;
    width: 80%;
    margin-left: 10%;
  }
  #job:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    
  }
  