.insert {
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    text-align: center;
    display: inline-block;
    margin: 10px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.5s ease;
    border: none;
    background-color: #2b96de !important;
}

.insert:hover {
    background-color: #185d8c !important;
    animation: scaleFadeAnimation 0.5s forwards;
}

.insert:focus {
    outline: none !important;
    box-shadow: none !important;
}

@keyframes scaleFadeAnimation {
    0% { transform: scale(1); opacity: 1; }
    50% { transform: scale(1.05); opacity: 0.8; }
    100% { transform: scale(1); opacity: 1; }
}
