
  
  fieldset {
    margin: 0;
    padding: 0;
    -webkit-margin-start: 0;
    -webkit-margin-end: 0;
    -webkit-padding-before: 0;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;
    -webkit-padding-after: 0;
    border: 0;
  }
  
  legend {
    margin: 0;
    padding: 0;
    display: block;
    -webkit-padding-start: 0;
    -webkit-padding-end: 0;
  }
  
  * {
    box-sizing: border-box;
  }
  

  
  .s128 form {
    width: 100%;
    max-width: 1000px;
    margin: 0;
  }
  
  .s128 form .inner-form .row {
    margin: 0px auto 80px auto;
  }
  
  .s128 form .inner-form .row .input-field {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
        justify-content: center;
  }
  
  .s128 form .inner-form .row .input-field input {
    height: 60px;
    width: 60px;
    border: 0;
    display: block;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    cursor: pointer;
  }
  
  .s128 form .inner-form .row .input-field input.placeholder {
    color: #000000;
    font-size: 18px;
    font-weight: 300;
    opacity: 1;
  }
  
  .s128 form .inner-form .row .input-field input:-moz-placeholder {
    color: #000000;
    font-size: 18px;
    font-weight: 300;
    opacity: 1;
  }
  
  .s128 form .inner-form .row .input-field input::-webkit-input-placeholder {
    color:#000000;
    font-size: 18px;
    font-weight: 300;
    opacity: 1;
  }
  
  .s128 form .inner-form .row .input-field input:hover, .s128 form .inner-form .row .input-field input:focus {
    box-shadow: none;
    outline: 0;
  }
  
  .s128 form .inner-form .row .input-field.first .clear {
    width: 60px;
    height: 60px;
    position: absolute;
    top: 0;
    right: 0;
    border: 0;
    background: transparent;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    transition: opacity .2s ease-out;
  }
  
  .s128 form .inner-form .row .input-field.first .clear svg {
    fill: #ccc;
    width: 22px;
    height: 22px;
  }
  
  .s128 form .inner-form .row .input-field.first .clear:hover, .s128 form .inner-form .row .input-field.first .clear:focus {
    box-shadow: none;
    outline: 0;
  }
  
  .s128 form .inner-form .row .input-field.first .clear:hover svg, .s128 form .inner-form .row .input-field.first .clear:focus svg {
    fill: #333;
  }
  
  .s128 form .inner-form .row .input-field.first input {
    padding: 10px 0 10px 60px;
    border-radius: 30px;
    width: 60px;
    transition: width .2s ease-in;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23ccc' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3e%3c/svg%3e");
    background-position: 14px 14px;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 34px 34px;
  }
  
  .s128 form .inner-form .row .input-field.first input:focus {
    width: 100%;
    padding-right: 60px;
  }
  
  .s128 form .inner-form .row .input-field.first input.isFocus {
    width: 100%;
  }
  
  .s128 form .inner-form .row .input-field.first input.isFocus ~ .clear {
    opacity: 1;
  }
  
  .s128 form .inner-form .row .input-field.second input {
    padding: 10px 60px 10px 0;
    border-radius: 3px;
    width: 60px;
    transition: width .3s;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg fill='%23ccc' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3e%3cpath d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'/%3e%3c/svg%3e");
    background-position: calc(100% - 14px) 14px;
    background-repeat: no-repeat;
    background-color: #fff;
    background-size: 34px 34px;
  }
  
  .s128 form .inner-form .row .input-field.second input.placeholder {
    color: #666;
    transition: opacity .1s;
  }
  
  .s128 form .inner-form .row .input-field.second input:-moz-placeholder {
    color: #666;
    transition: opacity .1s;
  }
  
  .s128 form .inner-form .row .input-field.second input::-webkit-input-placeholder {
    color: #666;
    transition: opacity .1s;
  }
  
  .s128 form .inner-form .row .input-field.second input:focus {
    width: 100%;
    padding-left: 15px;
  }
  
  .s128 form .inner-form .row .input-field.second input:focus.placeholder {
    opacity: 1;
  }
  
  .s128 form .inner-form .row .input-field.second input:focus:-moz-placeholder {
    opacity: 1;
  }
  
  .s128 form .inner-form .row .input-field.second input:focus::-webkit-input-placeholder {
    opacity: 1;
  }
  
  @media (min-width: 2500px) {
    .s128 form {
      width: 100%;
      max-width: 1600px;
      margin: 0;
    }
   }
   #img-header-2{
    width: 100%;
   }