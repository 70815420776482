

.course-card {
    width: 200px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    display: inline-block;
    margin: 20px;
    cursor: pointer;
    transition: background 0.3s ease, background-color 0.3s ease, transform 0.3s ease;
}
.course-card:hover{
    transform: translateY(-10px);
    background: linear-gradient(135deg, #b0d6f5, #92b7f2)
}
.course-card h3 {
    margin-top: 0;
    font-size: 18px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
}
.card-img {
    height: 150px;
    background: linear-gradient(135deg, #7EBFFA, #6F86D6);
    border-radius: 8px;
    margin-bottom: 10px;
}

h3 {
    margin-top: 0;
    font-size: 18px;
    font-weight: bold;
}

p {
    margin-bottom: 0;
    color: #666;
}
