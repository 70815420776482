.dashboard-container {
    padding: 20px;
    background: radial-gradient(circle at center, #c3d6f5, #a3c7f2);
    box-shadow: 0 2px 4px rgb(0, 0, 0, 0.1);
}

.course-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.highlight1{
    font-size: 30px;
    font-weight: bold;
    color: #245B8F;
    margin-left: 2rem;
    display: inline-block;
}
.ended-course-container{
    display:block;
}

