.NavbarInternItem{
    font-size: 30px;
    color:white;
    margin-left:5em;
    margin-right:5em;
    cursor: pointer;
    position: relative;
}
.NavbarIntern{
    display: flex;
    justify-content: center;
    padding: 1em;
    background: #0d1a3e;
}
.SelectedPage{
    font-weight: bold;
}

.NavbarInternItem:hover{
    color:aliceblue;
    user-select: none;
}

.NavbarDropDown {
    position: absolute; /* Position the dropdown menu absolutely */
    top: 100%; /* Position the dropdown menu below the navbar item */
    left: 50%;
    transform: translateX(-50%); /* Horizontally center the dropdown */
    background-color: #0d1a3e;
    opacity: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px 0;
    z-index: 1;
    display: none;
    min-width: 0;
    animation: fadeIn 0.1s ease-in-out forwards; /* Add the fadeIn animation */
}
.NavbarDropDown,
.NavbarInternItem.SelectedPage .NavbarDropDown {
    display: block;
}
.NavbarDropDown a {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: #333;
    white-space: normal; /* Allow the text to wrap */
    word-break: break-word; /* Break the text at word boundaries if necessary */
    width: 20rem;
}
.NavbarDropDown a:hover {
    background-color: #09215c;
}