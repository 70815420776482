.pfp-container{

}
.info-background{
    width: 100%;
    display:flex;
}
.pfp-container{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width:30%;
    padding:2rem;
}
.user-name{
    font-weight:bold;
    margin-bottom:2rem;
    color:var(--text-color);
    font-size: 2rem;
}
.user-info-container{
    border-left: rgba(148, 152, 188, 0.84) solid 0.2rem;
    padding:2rem;
}
.field-name{
    display:inline-block;
    color:var(--text-color);
    font-size:1.4rem;
}
.field-value{
    display:inline-block;
    color:var(--text-color);
    font-size:1.4rem;
    font-weight:bold;
}
.profile-pic {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    margin-bottom: 10px;
}

.highlight1{
    font-size: 30px;
    font-weight: bold;
    margin: 1rem 2rem;
    color: #245B8F;
    display:inline-block;
    padding : 0px;
}