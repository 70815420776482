#h1-aboutUs {
  color: black;
  font-size: 5rem;
}
#aboutUs-content {
  background-color: #3a3535;
  margin-right: 20px;
  color: white;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 3rem;
  letter-spacing: -0.08em;
  color: #ffffff;
  width: 50%;
}
#aboutUs-content div {
  margin: 5% 0;
  padding-left: 4%;
  padding-right: 3%;
}
#img-about-us {
  margin-left: 20px;
  width: 40%;
  height: 100%;
}
#aboutUs-content div p{
  color: white;
}
@media (max-width: 768px) {
  #img-about-us {
    margin-left: 20px;
    width: 100%;
    height: 100%;
  }
  #aboutUs-content {
    font-size: 1rem;
    width: 100%;
  }
}
@media (min-width: 1200px) {
  #aboutUs-content {
    font-size: 1.5rem;
    width: 50%;
  }
  #img-about-us {
    margin-left: 20px;
    width: 40%;
    height: 100%;
  }
}
@media (min-width: 2500px) {
  #img-about-us {
    margin-left: 20px;
    width: 40%;
    height: 100%;
  }
  #aboutUs-content {
    font-size: 2.5rem;
    width: 50%;
    line-height: 5rem;
  }
}
