.application-container {
    max-width: 1800px;
    margin: 0 auto 5% auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0px 50px rgba(0, 0, 0, 0.3);
    transition: all .5s ease !important;
  }
  .application-container-popup{
    margin: 0 auto 5% auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    overflow-y: auto;
    max-height: 80vh;
    box-shadow: 0 0px 50px rgba(0, 0, 0, 0.3);
    transition: all .5s ease !important;
  }
  .application-container-chart {
    max-width: 1800px;
    margin: 0 auto 5% auto;
    padding: 2rem;
    width: 80%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0px 50px rgba(0, 0, 0, 0.3);
    transition: all .5s ease !important;
  }
  /* .application-container-job {
    max-width: 1800px;
    margin: 2.5% auto 2.5% auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0px 50px rgba(0, 0, 0, 0.3);
    transition: all .5s ease !important;
  } */
  .application-container h1 {
    font-size: 2.6rem;
    margin-bottom: 1.5rem;
    text-align: center;
    color: #0D1A3E;
    transition: all .5s ease !important;
  }
  
  .application-container p {
    color: #666;
    font-weight: 300;
    transition: all .5s ease !important;
  }
  
  .application-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
    transition: all 0.5 ease;

  }
  
  .application-container th, .application-container td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
    transition: all .5s ease !important;
  }
  
  .application-container th {
    background-color: #f5f5f5;
    color: #555;
    font-weight: 500;
    transition: all .5s ease !important;
  }
  
  .application-container tr:nth-child(even) {
    background-color: #f9f9f9;
    transition: all .5s ease !important;
  }
  
  .application-container button {
    padding: 0.5rem 1rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .application-container button:hover {
    background-color: #0056b3;
  }
  
  .pagination-controls, .page-size-controls {
    display: flex;
    align-items: center;
    margin-top: 1rem;
  }
  
  .pagination-controls button {
    margin-right: 0.5rem;
  }
  
  .pagination-controls span {
    margin: 0 0.5rem;
  }
  
  .page-size-controls {
    margin-left: auto;
  }
  
  .page-size-controls select {
    padding: 0.25rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  .page{
    color: black;
  }
  .pagination-style-one a { 
    padding: 2px 8px; 
    background: #ffffff;
    color: #000000; 
    box-shadow: 0px 5px 10px 0px rgba(0,0,0,.08);
    margin: 1px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(160, 160, 160, 0.908);
    font-family: Arial, Helvetica, sans-serif;
    border-radius: 7px;
    margin: 2% 0;
  }
  
  .pagination-style-one a.selected, 
  .pagination-style-one a:hover, 
  .pagination-style-one a:active, 
  .pagination-style-one a:focus { 
    background-color: #2936a7d2;
    padding: 4px 16px; 
  }
  .pagination-style-one a.selected{
    background-color: #1a2695;
    color: white;
  }
#pagination-number-box{
    padding-left: 15px;
    padding-right: 15px;
}
.header th{
  /* background-color: #007bff; */
  border-style: none !important;
  background-color: #000000 !important;
  font-size: 1.3rem !important;
  color: white !important;
  font-weight: bolder !important;
}
#table{
  border-spacing: 0 !important;
  border-collapse: separate !important;
  overflow: hidden !important;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3) !important;
  transition: all 0.5 ease !important;
}
#table tbody tr td{
  color: #000000 !important;
}
#search-input{
  border-radius: 10px;

}
.box {
  position: relative;
}
.input-group {
  position: relative;
}

.input-search {
  padding: 15px;
  padding-left: 4%;
  border-radius: 10px !important;
  border-style: solid;
  border-width: 3px;
  width: 40%;
  transition: all 0.5s ease;
}

.input-group-prepend {
  position: absolute;
  left: 31%; /* Adjust as necessary */
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  transition: all 0.5s ease;
}

.input-group-text {
  background-color: transparent; /* Remove background */
  border: none; /* Remove border */
}

.input-group .input-group-prepend .input-group-text .fas {
  font-size: 20px; /* Icon size */
  color: black; /* Icon color */
}

.input-search:hover {
  width: 70%;
  padding-left: 50px; /* Increase padding to move text away from the icon */
}
#icon .input-group-text {
  border: none;
  background: none;
}
.input-search:hover ~ .input-group-prepend {
  left: 15.5%; 
}

#filter {
  margin: 1%;
  background-color: #000000;
  padding: 0.5%;
  color: white;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: bold;
}

@media (max-width: 1600px) {
  .application-container table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1.5rem;
    transition: all 0.5 ease;

  }
  .application-container{
    height: 100%;
  }
  .h1-add-intern{
    margin: 0;
    padding: 0;
  }

}
.Not-start,.Pending{
  background-color: #f7b721 ;
  padding: 2%;
  text-align: center;
  color: black !important;
  font-weight: bold !important;
  width: 70%;
}
.Finished,.Absent{
  background-color: #f51010 ;
  padding: 2%;
  text-align: center;
  color: #ffffff !important;
  font-weight: bold !important;
  width: 70%;
}
.Pending-rescheduling{
  background-color: #7c17f7 ;
  padding: 2%;
  text-align: center;
  color: #ffffff !important;
  font-weight: bold !important;
  width: 70%;
}
.Pending-Interview{
  background-color: #ff6411 ;
  padding: 2%;
  text-align: center;
  color: #ffffff !important;
  font-weight: bold !important;
  width: 70%;
}
.Passed{
  background-color:   #39ff14;
  padding: 2%;
  text-align: center;
  color: #ffffff !important;
  font-weight: bold !important;
  width: 70%;
}
.On-going,.Accept{
  background-color: #40f896 ;
  padding: 2%;
  text-align: center;
  color: black !important;
  font-weight: bold !important;
  width: 70%;
}
.button-update, .button-delete,.button-send-certificate,.button-close,.button-link,.button-logo,.button-signature {
  border-radius: 28px;
  padding: 10px 20px;
  color: white;
  font-weight: bold;
  text-align: center;
  display: inline-block;
  margin: 10px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease;
}
.button-signature{
  color: white !important;
}
.button-logo {
  background-color: #ef6895 !important;
  transition: all 0.5s ease;
}
.button-logo:hover{
  background-color: #923f5a  !important;
  animation: deleteAnimation 0.5s forwards;
}
.button-signature{
  background-color: #c831f6 !important;
  transition: all 0.5s ease;
}
.button-signature:hover{
  background-color: #7e219a  !important;
  animation: deleteAnimation 0.5s forwards;
}

.button-link{
  background-color: #fab027 !important;
  transition: all 0.5s ease;
}
.button-link:hover{
  background-color: #ba8523 !important;
  animation: deleteAnimation 0.5s forwards;
}

.button-delete,.button-close{
  background-color: #fa2727 !important;
  transition: all 0.5s ease;
}
.button-update, .button-send-certificate{
  background-color: #0091f9 !important;
  transition: all 0.5s ease;
  
}
.button-update:hover,.button-send-certificate:hover{
  background-color: #165887 !important;
  animation: cancelAnimation 0.5s forwards;
}
.button-delete:hover,.button-close:hover{
  background-color: #891616 !important;
  animation: deleteAnimation 0.5s forwards;
}

.button-update:focus,.button-delete:focus,.button-send-certificate:focus ,.button-close:focus,.button-link:focus{
  outline: none !important;
  box-shadow: none !important;
}
.tableJob {
  width: 100% !important;
  border-collapse: collapse !important;
}
.tableJob th, .tableJob td {
  padding: 8px !important;
}
.tableJob tbody td {
  border: 1px solid #ddd !important;
}

.tableJob tr {
  background-color: transparent !important;
}
.jobDes p{
  font-weight: bold !important;
}
.jobDes{
  font-weight: bold;
}
.width-view{
  width: 0%;
}
.detail p{
  color: #000000 !important;
  font-weight: 320;
}
.smaller{
  width: 90% !important;
}